<template>
  <div v-if="this.$store.state.editTemplateData">
    <div
      class="modal fade px-0"
      id="editTemplate-2"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div class="modal-dialog modal-md mx-0 mx-md-auto" role="document">
        <div class="modal-content animate-bottom br-18 b-gold">
          <div
            class="modal-header mb-0 d-flex p-0 align-items-center"
            style="border-bottom: 0.5px solid #93751f80 !important"
          >
            <span class="col-2"></span>
            <h5
              class="modal-title font-md-18 font-16 px-0 pb-12p pb-md-20 pt-md-22 pt-14p text-gold col-8 text-center kallisto-bold"
            >
              Edit template
            </h5>
            <button
              type="button"
              class="close m-0 col-2 pr-20 d-flex justify-content-end"
              data-dismiss="modal"
              @click="emptyModalData()"
            >
              <img
                :src="getIcon('x-button.svg')"
                alt="x-button"
                class="x-button"
              />
            </button>
          </div>
          <div class="modal-body px-0 mr-0 pt-24 text-left os-regular">
            <div class="row px-5 mx-0 mt-0">
              <div class="col-lg-12 d-flex px-0 pt-0 pb-10p">
                <label class="font-18 font-md-16 font-weight-bold mb-0">
                  Template Name
                </label>
              </div>
              <div
                class="col-lg-12 px-0 py-0 mb-2 border-bottom-white hide-scrollbar"
              >
                <div class="form-group">
                  <input
                    v-model="templateName"
                    type="text"
                    name="name"
                    id="name-e"
                    class="form-control text-white text-left font-md-14"
                    placeholder="Enter a Title"
                  />
                </div>
                <span
                  v-if="this.errors[0].createTemplateNameError"
                  class="text-danger"
                  >{{ this.errors[0].createTemplateNameError }}</span
                >
              </div>
            </div>
            <div class="row px-5 mx-0">
              <div class="col-lg-12 d-flex align-items-center px-0 pt-2 pb-10p">
                <label class="font-18 font-md-16 font-weight-bold mb-0"
                  >Availability
                </label>
              </div>
              <div class="col-lg-12 px-0 py-0 mb-2 border-bottom-white pb-5">
                <div class="switch-button">
                  <input
                    class="switch-button-checkbox"
                    type="checkbox"
                    v-model="availability"
                  />
                  <label class="switch-button-label" for=""
                    ><span class="switch-button-label-span">Public</span></label
                  >
                </div>
                <span
                  v-if="this.errors[0].createTemplateAvailabilityError"
                  class="text-danger"
                  >{{ this.errors[0].createTemplateAvailabilityError }}</span
                >
              </div>
            </div>
            <div class="row mb-md-130 mb-2 mt-0 px-5 mx-0 pt-4">
              <div class="col-lg-12 px-0 py-0">
                <label class="font-18 font-md-16 font-weight-bold mb-2"
                  >Time Slots</label
                >
              </div>
              <div class="col-lg-12 px-0 py-0 pb-4 mb-2 border-bottom-white">
                <div class="row time-slot-row">
                  <div
                    v-for="(ts, i) in TimeSlots"
                    :key="i"
                    class="col-4 d-flex align-items-top time-slot-col"
                  >
                    <input
                      v-model="ts.timeSlot"
                      type="text"
                      class="form-control time-slots text-white font-md-14"
                      placeholder="HH:MM"
                      v-mask="timeMask"
                      aria-label="time slot"
                    />
                  </div>
                </div>
                <span
                  v-if="this.errors[0].createTemplateTimeSlotsError"
                  class="text-danger mt-5 d-inline-block"
                  >{{ this.errors[0].createTemplateTimeSlotsError }}</span
                >
                <span
                  v-if="this.errors[0].createTemplateTimeSlotsLengthError"
                  class="text-danger mt-5 d-inline-block"
                  >{{ this.errors[0].createTemplateTimeSlotsLengthError }}</span
                >
              </div>
            </div>

            <div class="modal-footer d-flex justify-content-center">
              <button
                type="button"
                class="apply-button"
                @click="addATemplate()"
              >
                <span class="font-weight-bold apply">Edit template</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { API } from "aws-amplify";

export default {
  components: {},
  data: () => ({
    //Create Template Modal Data *********************
    createStartDate: "",
    createStartTime: "",
    createName: "",
    availability: false,
    TimeSlots: [
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
      { timeSlot: "" },
    ],
    templateName: "",
    isPublic: "",

    errors: [
      {
        createTemplateNameError: "",
        createTemplateAvailabilityError: "",
        createTemplateTimeSlotsError: "",
        createTemplateTimeSlotsLengthError: "",
      },
    ],
  }),
  mounted() {
    this.templateName = this.$store.state.editTemplateData.schedule_name;
    for (let [
      index,
      time,
    ] of this.$store.state.editTemplateData.local_times.entries()) {
      var str = time.toString();

      if (str.length == 3) {
        str = "0" + str[0] + str[1] + str[2];
      }
      str = str[0] + str[1] + ":" + str[2] + str[3];
      this.TimeSlots[index].timeSlot = str;
    }
    $("#editTemplate-2").modal("show");
  },
  methods: {
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    onChangeAvailabilty(event) {
      this.availability = event.target.value;
    },
    async addATemplate() {
      if (this.templateName === "") {
        this.errors[0].createTemplateNameError =
          "Template name cannot be empty.";
      } else {
        this.errors[0].createTemplateNameError = "";
      }
      var timeSlotsToPost = [];

      this.TimeSlots.forEach((ts) => {
        if (ts.timeSlot != "") {
          var timeSplit = ts.timeSlot.toString().split(":");
          var timeSlotToPost = timeSplit[0] + timeSplit[1];
          timeSlotsToPost.push(parseInt(timeSlotToPost));
        }
      });
      if (timeSlotsToPost.length == 0) {
        this.errors[0].createTemplateTimeSlotsError =
          "Please fill in at least one time slot.";
      } else {
        this.errors[0].createTemplateTimeSlotsError = "";
      }

      if (
        this.errors[0].createTemplateTimeSlotsError != "" ||
        this.errors[0].createTemplateAvailabilityError != "" ||
        this.errors[0].createTemplateNameError != ""
      ) {
        return -1;
      } else {
        $("#editTemplate-2").modal("hide");
        var data = {
          body: {
            schedule_name: this.templateName,
            public: this.availability ? false : true,
            local_times: timeSlotsToPost,
          },
        };

        await API.patch(
          "asulink",
          `/scheduler/schedule/${this.$store.state.editTemplateData.schedule_id}`,
          data
        )
          .then(async () => {
            $("#edit-template-sucess").modal();

            await this.$store.dispatch("setListOfTemplates").then(async () => {
              var data = [];
              this.$store.state.templates.forEach((ts) => {
                var template = ts;
                template["checked"] = false;
                data.push(template);
              });
              this.$store.state.batchTemplateMultiSelect = data;
            });
          })
          .catch(() => {
            this.$toasted.show(`Error Occured`, {
              position: "top-right",
              duration: 5000,
            });
          });

        this.emptyModalData();
      }
    },
    emptyModalData() {
      this.availability = false;
      this.templateName = "";
      this.TimeSlots.forEach((ts) => {
        ts.timeSlot = "";
      });
      this.$store.state.editTemplateData = {};
      this.$emit("showEditTemplateTwo", false);
    },
  },
};
</script>

<style lang="scss">
.modal {
  input {
    background-color: #151515;
  }
  .modal-content {
    background-color: #151515;
  }
}

#editTemplate-2 {
  input,
  select {
    border: none;
    background-color: #2d2d2d !important;
  }
  @media only screen and (max-width: 767px) {
    input {
      border: none;
      background-color: #2d2d2d !important;
    }
    .modal-footer {
      border-top: none;
    }
    .apply-button {
      border-radius: 6px !important;
      font-size: 16px !important;
      height: 38px !important;
      width: 262px !important;
      color: #000000 !important;
      background: #ffc627 !important;
    }
    .template-v-for::-webkit-scrollbar {
      display: none;
    }
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
    }
  }
  overflow: auto !important;
  .modal-content {
    background-color: #151515;
  }
  input {
    height: 53px;
    color: white;
  }

  .time-slot-row {
    max-height: 200px;
    overflow-y: auto;
  }

  .availability-ctm {
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    max-width: 113px;
    height: 41px;
    background-color: #151515 !important;
  }
  .time-slots {
    max-width: 128px;
    max-height: 41px;
  }
  .modal-footer {
    border-top: none;
  }
  .apply-button {
    border: 1px solid #ffc627 !important;
    font-size: 16px;
    border-radius: 8px;
    width: 180px;
    height: 42px;
    color: #000000 !important;
    background: #ffc627 !important;
  }
}
</style>
